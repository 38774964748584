import _ from '../../common/utility';

function nav () {
    let $specialty;
    let $dropdown = $('#specialty-dropdown');

    if ($dropdown.length ) {
        $specialty = $('<div class="header-specialty-menu">');
        $dropdown.addClass('resp-container').find('a').addClass('header-specialty-menu-link');
        $specialty.append($dropdown).append('<button class="header-specialty-close-button js-close-dropdown"><svg width="23" height="24" viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg"><g stroke="#007CB0" stroke-width="3" fill="none" fill-rule="evenodd" stroke-linecap="round"><path d="M1.992 2.445l19.32 19.593M21.31 2.445L1.993 22.038"></path></g></svg></button>')
    } else {
        $specialty = $('.header-specialty-menu').clone();
    }

    $specialty
        .attr('id', 'specialtyMenu')
        .appendTo('.hp_specialty')
        .addClass('specialty-menu');

    $specialty.find('.js-close-dropdown').click(specialtyToggle);
    $('#specialtyToggle').click(specialtyToggle);

    function specialtyToggle () {
        let breakPoint = _.getBreakpoint();
        if($('#specialtyMenu').hasClass('is-expanded') && breakPoint > 1){
            $("body").addClass("dropdown-view");
        }
        $('#specialtyMenu').toggleClass('is-expanded');
        $('#specialtyToggle').find('.sp-arrow').toggleClass('is-rotated');
        $('.whiteout-specialty-down').toggleClass('is-visible');
        $('.hp_specialty').toggleClass('is-overflow-visible');
    }

    $('.js-expand-button').click(function () {
        $('#specialtyMenu').removeClass('is-expanded');
        $('#specialtyToggle').find('.sp-arrow').removeClass('is-rotated');
        $('.whiteout-specialty-down').removeClass('is-visible');
        $('.hp_specialty').removeClass('is-overflow-visible');
    })
}

export default _.catch(nav);