import _ from './utility'

function ads() {

    var LazyLoadHomePage141 = {

        lastScrollTop: 0,
        init: function () {

            if (breakpoint !== 1) {
                LazyLoadHomePage141.addPlaceHolders();

                $(window).off("scroll.lazyLoadHp141").on("scroll.lazyLoadHp141", function () {
                    LazyLoadHomePage141.startLazyLoadHp141();
                });
            }

            // to avoid issue when adblocker is enabled; temp solution
            if (typeof webmd.ads2.sizes !== "undefined") {
                webmd.ads2.sizes[122] = [//temp disable 910
                    [300, 250],
                    [300, 350]
                ];
            }

            if (window.PageMetadata.webSegVars.pc === 'hp' && window.PageMetadata.reqHeaders.device === 'PC'){
                webmd.ads2.sizes[122].push([300,500],[300,340]);
            };

        },
        addPlaceHolders: function () {

            var $141AdLoc = $(".page-body section").not(".hp_alerts").slice(4);// ignore first 4 sections excluding alerts
            var al_index = 1; //ad location index

            $141AdLoc.each(function (index) {
                index += 1;
                if (index % 3 === 0) {
                    var $new141 = $("<div></div>");
                    $new141.attr('id', "ads-pos-141_" + al_index);
                    $new141.attr('class', "lazyloadedHp141");
                    $(this).after($new141);
                    al_index = al_index + 3;
                }
            });

        },
        startLazyLoadHp141: function () {

            var st = $(window).scrollTop();
            if (st > LazyLoadHomePage141.lastScrollTop) {
                LazyLoadHomePage141.lazyLoadHp141("prev");
            } else {
                LazyLoadHomePage141.lazyLoadHp141("next");
            }
            LazyLoadHomePage141.lastScrollTop = st;

            LazyLoadHomePage141.removeLazyLoadEvent();
        },

        lazyLoadHp141: function (dir) {
            var $lazyloaded141s = $(".lazyloadedHp141");
            $lazyloaded141s.each(function () {
                var ele = (dir === "next") ? $(this).next() : $(this).prev();

                if (ele.length === 0 || ele.css("display") === "none") {
                    if (LazyLoadHomePage141.isScrolledIntoView($(this)) && !$(this).hasClass('AdUnit')) {
                        LazyLoadHomePage141.makeAdCall($(this));
                    }
                } else {
                    if (LazyLoadHomePage141.isScrolledIntoView(ele) && !$(this).hasClass('AdUnit')) {
                        LazyLoadHomePage141.makeAdCall($(this));
                    }
                }
            });
        },

        makeAdCall: function (ele) {
            ele.addClass("AdUnit");
            var adloc = ele.attr('id').split("_")[1];
            webmd.ads2.setPageTarget('al', adloc);
            webmd.ads2.setPageTarget('ll', 1);
            webmd.ads2.defineAd({
                id: ele.attr('id'),
                pos: '141',
                display: false
            });
            webmd.ads2.refresh({ id: ele.attr('id') });
        },

        removeLazyLoadEvent: function () {

            var $lazyloaded141s = $(".lazyloadedHp141");

            var hasFinishLoading = true;
            $lazyloaded141s.each(function () {
                if (!$(this).hasClass('AdUnit')) {
                    hasFinishLoading = false;
                    return;
                }
            });

            if (hasFinishLoading) {

                $(window).off("scroll.lazyLoadHp141");
            }
        },
        isScrolledIntoView: function (elem) {
            var pageTop = $(window).scrollTop();
            var pageBottom = pageTop + $(window).height();
            var elementTop = $(elem).offset().top;
            var elementBottom = elementTop + $(elem).height();

            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));

        }

    };

    var LazyLoadHomePage1122 = {
        lastScrollTop: 0,
        init: function () {

            if (breakpoint === 1) {
                LazyLoadHomePage1122.addPlaceHolders();
                setTimeout(function () {
                    $(window).off("scroll.lazyLoadHp1122").on("scroll.lazyLoadHp1122", function () {
                        LazyLoadHomePage1122.startLazyLoadHp1122();
                    });
                }, 500);
            }
        },
        addPlaceHolders: function () {

            var al_index = 1;
            var $1122AdLoc = $(".page-body section").not(".hp_alerts").slice(3);// ignore first 3 sections excluding alerts
            $1122AdLoc.each(function (index) {
                index += 1;
                if (index % 2 === 0) {
                    var $new1122 = $("<div></div>");
                    $new1122.attr('id', "ads-pos-1122_" + al_index);
                    $new1122.attr('class', "lazyloadedHp1122");
                    $(this).after($new1122);
                    al_index = al_index + 2;
                }
            });

        },
        startLazyLoadHp1122: function () {
            if (window.PageMetadata.webSegVars.pc === 'hp' && window.PageMetadata.reqHeaders.device === 'MOBILE'){
                webmd.ads2.sizes[1122] = webmd.ads2.sizes[1122].filter(s=> { return JSON.stringify(s) !== JSON.stringify([300, 500]) && JSON.stringify(s) !== JSON.stringify([300, 340])})
            }
            var st = $(window).scrollTop();
            if (st > LazyLoadHomePage1122.lastScrollTop) {
                LazyLoadHomePage1122.lazyLoadHp1122("prev");
            } else {
                LazyLoadHomePage1122.lazyLoadHp1122("next");
            }
            LazyLoadHomePage1122.lastScrollTop = st;            
            LazyLoadHomePage1122.removeLazyLoadEvent();
        },
        lazyLoadHp1122: function (dir) {
            var $lazyloaded1122s = $(".lazyloadedHp1122");
                       
            $lazyloaded1122s.each(function () {
                var ele = (dir === "next") ? $(this).next() : $(this).prev();
                if (ele.length === 0 || ele.css("display") === "none") {
                    if (LazyLoadHomePage1122.isScrolledIntoView($(this)) && !$(this).hasClass('AdUnit')) {
                        LazyLoadHomePage1122.makeAdCall($(this));
                    }
                } else {
                    if (LazyLoadHomePage1122.isScrolledIntoView(ele) && !$(this).hasClass('AdUnit')) {
                        LazyLoadHomePage1122.makeAdCall($(this));
                    }
                }
            });
        },

        makeAdCall: function (ele) {
            ele.addClass("AdUnit");
            var adloc = ele.attr('id').split("_")[1];
            webmd.ads2.setPageTarget('al', adloc);
            webmd.ads2.setPageTarget('ll', 1); 
            
            if(DFPTargetKeys.userSegVars.dt != undefined){
                var adID = ele.attr('id');
                profads.ads2.proclivityRefresh('ads-pos-1122', adID);
            }
            else{
                webmd.ads2.defineAd({
                    id: ele.attr('id'),
                    pos: '1122',
                    display: false,
                    sizes:webmd.ads2.sizes[1122]
                });
                webmd.ads2.refresh({ id: ele.attr('id') });
            }

        },
        removeLazyLoadEvent: function () {
            var $lazyloaded1122s = $(".lazyloadedHp1122");
            var hasFinishLoading = true;
            $lazyloaded1122s.each(function () {
                if (!$(this).hasClass('AdUnit')) {
                    hasFinishLoading = false;
                    return;
                }
            });

            if (hasFinishLoading) {
                $(window).off("scroll.lazyLoadHp1122");
            }
        },
        isScrolledIntoView: function (elem) {
            var pageTop = $(window).scrollTop();
            var pageBottom = pageTop + $(window).height();
            var elementTop = $(elem).offset().top;
            var elementBottom = elementTop + $(elem).height();

            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));

        }

    };


    LazyLoadHomePage141.init();
    LazyLoadHomePage1122.init();
    $(window).resize(function () {

        if (breakpoint === 1) {

            $(".lazyloadedHp141, #ads-pos-104").addClass("hide-141-104-ads");


        } else {

            $(".lazyloadedHp141, #ads-pos-104").removeClass("hide-141-104-ads");


        }

    });


    /* (START) Sticky Header 1004 Ad */

    var stickMobile1004;
    if (breakpoint === 1) {
        (function ($) {
            function StickMobile1004() {
                this.stopStickyTime = 5000;
                this._timerHandler = this.timerHandler.bind(this);
            }
            ;

            StickMobile1004.prototype.init = function () {
                if (!$(".mobile_adlabelleft").length) {
                    return false;
                }
                this.$adtagheader = $(".mobile_adlabelleft").eq(0);
                this.$window = $(window);
                this.originalAdHeight = this.$adtagheader.outerHeight() + 50;
                this.originalTopAdTop = this.$adtagheader.offset().top;
                webmd.ads2.addSlotRenderEndedCallback("ads-pos-1004", this._timerHandler);
                $(document).on("animationOffStickStep", function () {
                    var top = $("#ads-pos-1004").outerHeight(true);
                    if (top <= 0)
                        top = 0;
                    $("#article-nav.fixed-sticky").css("top", top + "px");
                });
                this.addSticky();
            };

            StickMobile1004.prototype.timerHandler = function () {
                var self = this;
                var timerid = setTimeout(function () {
                    self.animationOffSticky();
                    clearTimeout(timerid);
                }, this.stopStickyTime);
            };

            StickMobile1004.prototype.isSticky = function () {
                var pos = $("#headerbox").offset().top + $("#headerbox").outerHeight(true);
                return (this.$adtagheader.offset().top > pos);
            };

            StickMobile1004.prototype.addSticky = function () {
                this.$adtagheader.addClass("stickyHeader");
                $("body").addClass("stickyHeader");
                $(document).trigger("addSticky");
            };

            StickMobile1004.prototype.removeSticky = function () {
                this.$adtagheader.removeClass("stickyHeader");
                $("body").removeClass("stickyHeader");
            };

            StickMobile1004.prototype.animationOffSticky = function () {
                var self = this;
                if (this.isSticky()) {
                    $("#ads-pos-1004").slideUp({
                        duration: "slow",
                        step: function () {
                            $(document).trigger("animationOffStickStep");

                        },
                        complete: function () {
                            self.removeSticky();
                            $("#ads-pos-1004").show();
                        }
                    }); // end slideUp()
                } // end if
                else
                    self.removeSticky();
            };

            StickMobile1004.prototype.disableSticky = function () {
                this.removeSticky();
            };

            // create a new instance
            stickMobile1004 = new StickMobile1004();
        }($));

        if (typeof webmd.ads2.sizes !== "undefined" && typeof isNewsHomepage !== "undefined") {
            stickMobile1004.init();
        }

    }

    var stickDesktop104;
    (function ($) {
        stickDesktop104 = window.stickDesktop104 || {};
        stickDesktop104 = {
            killStickyHead: '',
            stickySettings: {
                $adcontainer104: $(".adcontainer104").eq(0),
                $headerbox: $("#headerbox"),
                $window: $(window),
                originalTopAdTop: '', //$(".adcontainer104").offset().top,
                originalAdHeight: ($(".adcontainer104").eq(0).outerHeight()),
                stopStickyHeight: 1100,
                stopStichyTime: 5000
            },
            init: function () {
                var self = this;
                // Do a check to make sure nobody tries to run init twice,
                if (self.init.flag) {
                    return;
                }
                self.init.flag = true;

                // hardcode a default height for now to prevent the sticky jumpiness if user scrolls.
                // Also, use timeout to get the original value if the header is not sticky yet
                stickDesktop104.stickySettings.originalTopAdTop = 104;
                var tmp = setTimeout(function () {
                    if ($(".adcontainer104").hasClass("stickHeader") === false) {
                        stickDesktop104.stickySettings.originalTopAdTop = $(".adcontainer104").offset().top;
                    }
                    clearTimeout(tmp);
                }, 1000);

                $(window).on('scroll', stickDesktop104.stickDesktop104scrollHandler);
                webmd.ads2.addSlotRenderEndedCallback("ads-pos-104", function () {
                    stickDesktop104.killStickyHead = setTimeout(function () {
                        if ($(".adcontainer104").hasClass("stickHeader")) {
                            stickDesktop104.animateOffSticky();
                        } else {
                            stickDesktop104.disableSticky();
                        }
                    }, stickDesktop104.stickySettings.stopStichyTime);
                }); // end addSlotRenderEndedCallback()
            },
            stickyRefresh: function () {
                clearTimeout(stickDesktop104.killStickyHead);
                $(window).on('scroll', stickDesktop104.stickDesktop104scrollHandler);
                stickDesktop104.killStickyHead = setTimeout(function () {
                    if ($(".adcontainer104").hasClass("stickHeader")) {
                        stickDesktop104.animateOffSticky();
                    } else {
                        stickDesktop104.disableSticky();
                    }
                }, stickDesktop104.stickySettings.stopStichyTime);
                $(document).trigger("stickyRefresh");
            },
            stickDesktop104scrollHandler: function () {
                var winScrollTop = stickDesktop104.stickySettings.$window.scrollTop();
                if (winScrollTop > stickDesktop104.stickySettings.$adcontainer104.offset().top) {
                    stickDesktop104.addSticky();
                } else if ($(".adcontainer104").offset().top <= stickDesktop104.stickySettings.originalTopAdTop) {
                    stickDesktop104.removeSticky();
                }
            },
            animateOffSticky: function () {
                stickDesktop104.disableSticky();
                stickDesktop104.stickySettings.$adcontainer104.slideUp({duration: "slow", step: function () {
                        $(document).trigger("animateOffStickyStep");
                    }, complete: function () {
                        stickDesktop104.stickySettings.$adcontainer104.hide();
                        stickDesktop104.stickySettings.$adcontainer104.slideDown(function () {
                            stickDesktop104.stickySettings.$adcontainer104.removeClass("stickHeader").addClass("unStickHeader");
                            stickDesktop104.stickySettings.$headerbox.css({
                                "padding-bottom": "0px"
                            });
                        });
                        stickDesktop104.stickySettings.$adcontainer104.show();
                    }});
                $(document).trigger("animationOffSticky");
            },
            stickyKillSwitch: function () {
                stickDesktop104.disableSticky();
                stickDesktop104.removeSticky();
            },
            disableSticky: function () {
                $(window).off('scroll', stickDesktop104.stickDesktop104scrollHandler);
            },
            addSticky: function () {
                stickDesktop104.stickySettings.$headerbox.css({
                    "padding-bottom": stickDesktop104.stickySettings.originalAdHeight + "px"
                });
                stickDesktop104.stickySettings.$adcontainer104.removeClass("unStickHeader").addClass("stickHeader");
            },
            removeSticky: function () {
                stickDesktop104.stickySettings.$adcontainer104.removeClass("stickHeader").addClass("unStickHeader");
                stickDesktop104.stickySettings.$headerbox.css({
                    "padding-bottom": "0px"
                });
            }
        };
    }($));

    // when adblocker is enabled don't run because profads2.js is blocked
    if (breakpoint !== 1 && typeof webmd.ads2.sizes !== "undefined" && typeof isNewsHomepage !== "undefined") {
        stickDesktop104.init();
    }    
}

export default _.catch(ads);